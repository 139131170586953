import "./App.css";
import React, { useState, useEffect } from "react";
import { useSpeechSynthesis } from "react-speech-kit";

import { useInterval } from "react-interval-hook";
import useKeypress from "react-use-keypress";

import Result from "./result";

function Stream(props) {
  const [startTime, setStartTime] = useState(Date.now());
  const [currentItem, setCurrentItem] = useState(-1);
  const [showResults, setShowResults] = useState(false);
  const [talk, setTalk] = useState(0);
  const [startPressed, setStartPressed] = useState(1);

  const { speak } = useSpeechSynthesis();

  const [eventsRecord, setEventsRecord] = useState([]);

  const buttonPressed = (source) => {
    setEventsRecord(
      eventsRecord.concat([{ type: "Button Press", source, time: Date.now() }])
    );
  };

  useKeypress(["ArrowUp", "ArrowDown"], (event) => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      buttonPressed(event.key);
    }
  });

  const { start, stop, isActive } = useInterval(
    () => {
      console.warn("onExpire called");
      if (currentItem + 1 > props.identifyList.length - 1) {
        stop();
        return;
      }

      setStartTime(Date.now());

      setCurrentItem(
        currentItem + 1 <= props.identifyList.length - 1 ? currentItem + 1 : 0
      );
    },
    3000,
    {
      autoStart: true,
      immediate: false,
      selfCorrecting: false,
      onFinish: () => {
        setEventsRecord(
          eventsRecord.concat([
            { type: "Stop", source: "Sequence Ended", time: Date.now() },
          ])
        );

        setShowResults(true);
      },
    }
  );

  const [active, setActive] = useState(isActive());

  useEffect(() => {
    if (currentItem > -1) {
      console.log("in useEffect");
      console.log("going to say ", props.identifyList[currentItem]);
      setEventsRecord(
        eventsRecord.concat([
          {
            type: "Speech",
            source: props.identifyList[currentItem],
            time: Date.now(),
          },
        ])
      );

      speak({ text: props.identifyList[currentItem] });
    }
  }, [currentItem]);

  return (
    <>
      {!startPressed ? (
        <div>
          <button
            onClick={() => {
              setStartPressed(1);
              start();
            }}
          >
            start
          </button>
        </div>
      ) : showResults === true ? (
        <Result
          eventsRecord={eventsRecord}
          // startTime={eventsRecord.sort((a, b) => a - b)[0].time}
          target={props.target}
          description={props.description}
        />
      ) : (
        <>
          <button
            style={{ width: "100%", height: "500px", fontSize: "3em" }}
            onClick={() => {
              buttonPressed("On Screen Button");
            }}
          >
            {props.identifyList[currentItem]}
          </button>
          {console.log(currentItem)}
          <button
            style={{
              width: "100%",
              height: "250px",
              backgroundColor: "peachpuff",
              fontSize: "1.5em",
            }}
            onClick={() => {
              setEventsRecord(
                eventsRecord.concat([
                  { type: "Stop", source: "Stop Button", time: Date.now() },
                ])
              );
              stop();
            }}
          >
            Stop
          </button>
        </>
      )}
    </>
  );
}

export default Stream;
