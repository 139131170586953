import logo from "./logo.svg";
import "./App.css";
import React, { useState } from "react";
import Stream1 from "./stream";
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from "@react-hook/window-size";

import DatasetSelector from "./DatasetSelector";

function App() {
  const [identifyList, setIdentifyList] = useState([]);
  const [target, setTarget] = useState([]);
  const [startTime, setStartTime] = useState(0);
  const [width, height] = useWindowSize();
  const onlyWidth = useWindowWidth();
  const onlyHeight = useWindowHeight();
  const [dataSet, setDataSet] = useState(null);
  const [description, setDescription] = useState("");

  return (
    <div className="App">
      {identifyList.length === 0 ? (
        <div style={{ width, height }}>
          <header className="App-header">
            <h2>Numbers Project</h2>
            <DatasetSelector setDataSet={setDataSet} dataSet={dataSet} />

            <p>
              {dataSet?.target ? `Target Number : ${dataSet.target}` : null}
            </p>

            <p>
              <span>Description (optional): </span>
              <input
                type="text"
                onChange={(e) => setDescription(e.target.value)}
              />
            </p>
            <button
              disabled={dataSet === null}
              onClick={() => {
                setIdentifyList(dataSet.list);
                setTarget(dataSet.target);
                setStartTime(Date.now);
              }}
            >
              Click Here to Start
            </button>
          </header>
        </div>
      ) : (
        <div>
          <header className="App-header">
            <Stream1
              identifyList={identifyList}
              target={target}
              description={description}
            />
          </header>
        </div>
      )}
    </div>
  );
}

export default App;
