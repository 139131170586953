import "./App.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { CSVLink, CSVDownload } from "react-csv";

function Result(props) {
  const [timeSpanList, setTimeSpanList] = useState([]);

  useEffect(() => {
    let outputList = props.eventsRecord;

    outputList.sort((a, b) => {
      return b.time - a.time;
    });

    for (let index = 0; index < outputList.length; index++) {
      if (index < outputList.length - 1)
        outputList[index].timeSpan =
          outputList[index].time - outputList[index + 1].time;
      else outputList[index].timeSpan = 0;
    }

    outputList.sort((a, b) => {
      return a.time - b.time;
    });

    outputList = outputList.map((event) => {
      const d = new Date(event.time);
      event.timeString = d.toString();

      return event;
    });

    // let targetHitCount = 0;
    let targetHits = [];
    let falseHits = [];
    // add analysis here. props.target is the expected number
    let previousIndexIsTarget = false;
    let previousTargetIsSpeech = false;
    for (let index = 0; index < outputList.length; index++) {
      const element = outputList[index];
      console.log("current Element is ", element);
      if (element.type === "Button Press") {
        if (previousIndexIsTarget) {
          outputList[index].Note = "Success";
          // targetHitCount++;
          targetHits.push(element.timeSpan);
        } else if (previousTargetIsSpeech) {
          outputList[index].Note = "False Alarm";
          falseHits.push(element.timeSpan);
        }
      }
      console.log(`looking for ${props.target}`);
      previousTargetIsSpeech = element.type === "Speech";
      previousIndexIsTarget =
        element.type === "Speech" && element.source === `${props.target}`;
      console.log(`previousIndexIsTarget = ${previousIndexIsTarget}`);
    }
    outputList.push({ Note: "" });
    outputList.push({
      source: "Successful hit count:",
      time: targetHits.length,
    });
    outputList.push({
      source: "Average time for successful response :",
      time: targetHits.reduce((partialSum, a) => partialSum + a, 0),
    });
    outputList.push({ Note: "" });
    outputList.push({ source: "False hit count:", time: falseHits.length });
    outputList.push({
      source: `Average time for false response :`,
      time: falseHits.reduce((partialSum, a) => partialSum + a, 0),
    });
    // add summary here for average response time on a target

    setTimeSpanList(outputList);
  }, []);

  useEffect(() => {
    axios.post(
      "https://0lr5mup0i7.execute-api.ca-central-1.amazonaws.com/dev/api/summary",
      props
    );
  }, []);

  return (
    <div style={{ width: "100%", backgroundColor: "grey" }}>
      <VerticalTimeline>
        {timeSpanList
          .filter((element) => element.type)
          .map((event, x) => {
            return (
              <VerticalTimelineElement
                key={`ResultItem${x}`}
                className="vertical-timeline-element--work"
                contentStyle={
                  event.type === "Stop"
                    ? { background: "rgb(233, 30, 99)", color: "#fff" }
                    : event.type === "Button Press"
                    ? { background: "rgb(30, 130, 100)", color: "#fff" }
                    : { background: "rgb(33, 150, 243)", color: "#fff" }
                }
                contentArrowStyle={{
                  borderRight: "7px solid  rgb(33, 150, 243)",
                }}
                date={`${event.timeSpan} ms`}
                iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
              >
                <h3 className="vertical-timeline-element-title">
                  {event.type}
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  {event.source}
                </h4>
              </VerticalTimelineElement>
            );
          })}
      </VerticalTimeline>

      <CSVLink
        data={timeSpanList}
        filename={`${props.description ? props.description : "data"}.csv`}
      >
        Download
      </CSVLink>
    </div>
  );
}

export default Result;
