import "./App.css";
import React, { useState, useEffect } from "react";

import data from "./default/data.json";

function DatasetSelector({ setDataSet, dataSet }) {
  const [selectedType, setSelectedType] = useState("0back");
  // const [selectedDataSet, setSelectedDataSet] = useState("");

  return (
    <div style={{ display: "flex", gap: "50px" }}>
      <div>
        {[...new Set(data.map((x) => x.type))].map((type) => (
          <div
            key={type}
            style={{
              border:
                selectedType === type
                  ? "2px solid rgba(200, 0, 0, 1)"
                  : "2px solid rgba(100, 100, 100, 1)",
              backgroundColor:
                selectedType === type
                  ? "rgba(200, 0, 0, .3)"
                  : "rgba(100, 100, 100, .3)",
              cursor: "pointer",
              borderRadius: 10,
            }}
            onClick={(event) => {
              setSelectedType(type);
              // setSelectedDataSet("");  //dislike this two setdataset calls
              setDataSet(null);
            }}
          >
            {type}
          </div>
        ))}
      </div>
      <div>
        {data
          .filter((item) => {
            return item.type === selectedType;
          })
          .map((item) => (
            <div
              key={item.name}
              style={{
                border:
                  dataSet?.name === item.name
                    ? "2px solid rgba(200, 0, 0, 1)"
                    : "2px solid rgba(100, 100, 100, 1)",
                backgroundColor:
                  dataSet?.name === item.name
                    ? "rgba(200, 0, 0, .3)"
                    : "rgba(100, 100, 100, .3)",
                cursor: "pointer",
                borderRadius: 10,
              }}
              onClick={(event) => {
                // setSelectedDataSet(item);
                setDataSet(item);
              }}
            >
              {item.name}
            </div>
          ))}
      </div>
    </div>
  );
}

export default DatasetSelector;
